import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
		meta: {
			keepAlive: true // 该路由会被缓存
		}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/backtesting',
    name: 'Backtesting',
    component: () => import(/* webpackChunkName: "about" */ '../views/Backtesting.vue')
  },
  {
    path: '/time-share',
    name: 'timeShare',
    component: () => import(/* webpackChunkName: "about" */ '../views/time-share.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
