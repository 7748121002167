<template>
	<div class="home">

		<div class="choose-box">
			<div class="select-code">
				<el-select v-model="value" filterable size="mini" placeholder="请选择" @change="change">
					<el-option v-for="item in options" :key="item.code" :label="item.name"
						:value="item.code"></el-option>
				</el-select>
			</div>

			<div class="mark_daily_line">
				<span>组合标记：</span>
				<el-switch v-model="mark_daily_line" @change="dlchange"></el-switch>
				<span v-if="mark_daily_line" style="margin-left: 10px;">单选：</span>
				<el-switch v-if="mark_daily_line" v-model="mark_daily_choose"></el-switch>
			</div>

			<!-- <div class="mark_daily_line" v-show="mark_daily_line">
				<el-select v-model="kline_start" placeholder="请选择">
					<el-option
						v-for="(item, index) in kline_categoryData"
						:key="index"
						:label="item"
						:value="index">
					</el-option>
				</el-select>
			</div>
			<div class="mark_daily_line" v-show="mark_daily_line">
				<el-select v-model="kline_end" placeholder="请选择">
					<el-option
						v-for="(item, index) in kline_categoryData"
						:key="index"
						:label="item"
						:value="index">
					</el-option>
				</el-select>
			</div> -->
			<el-button size="mini" @click="changePeriod(item.type)" v-for="item in periodList" :key="item.type"
				:type="period == item.type ? 'primary' : ''">{{ item.name }}</el-button>

			<el-button type="text" @click="time">分时截图</el-button>
			
			<el-button type="primary" size="mini" @click="getKline()">刷新数据</el-button>
			
		</div>

		<!-- v-if="!mark_daily_choose && moreClickItem.length" -->
		<div class="choose-item">
			<h6>已选列表</h6>
			<!-- closable -->
			<el-tag size="mini" v-for="(item, index) in moreClickItem" :key="index" style="margin: 5px;"
				@close="tagclose(item, index)">
				{{ item.date }}
				<!-- {{ `${item.date.slice(0, 4)}-${item.date.slice(4, 6)}-${item.date.slice(6, 8)}` }} -->
			</el-tag>
		</div>

		<br>
		<!-- v-if="!mark_daily_choose && moreClickItem.length" -->
		<div class="choose-box">
			来源：
			<div class="">
				<el-select v-model="tactics" filterable size="mini" placeholder="请选择" @change="getKline()">
					<el-option v-for="item in hows" :key="item.value" :label="item.name"
						:value="item.value"></el-option>
				</el-select>
			</div>
			
			
			<el-popover
				placement="top"
				width="400"
				trigger="hover"
				v-if="tactics == 4">
				<div>
					<div class="mark_daily_line">
						<span>增加标记组合：</span>
						<el-input size="mini" type="text" placeholder="请输入组合名称" v-model="name"
							style="width: 200px;">
						</el-input>
						<el-button size="mini" @click="submitKlineComposeGroup">添加</el-button>
					</div>
				</div>
				<div slot="reference">组合：</div>
			</el-popover>
			<div class="" v-if="tactics == 4">
				<el-select v-model="selectGroup" filterable size="mini" placeholder="请选择">
					<el-option v-for="item in GroupLists" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
			</div>
			类型：
			<el-select size="mini" v-model="composetype" placeholder="请选择" style="margin-right: 10px;">
				<el-option label="未标记" :value="0"></el-option>
				<el-option label="空转多" :value="1"></el-option>
				<el-option label="多转空" :value="-1"></el-option>
				<el-option label="大阳线" :value="2"></el-option>
				<el-option label="大阴线" :value="3"></el-option>
				<el-option label="光头光脚大阳线" :value="4"></el-option>
				<el-option label="光头光脚大阴线" :value="5"></el-option>
				<el-option label="锤子阳线" :value="6"></el-option>
				<el-option label="锤子阴线" :value="7"></el-option>
				<el-option label="倒锤阳线" :value="8"></el-option>
				<el-option label="倒锤阴线" :value="9"></el-option>
				<el-option label="小阳线" :value="10"></el-option>
				<el-option label="小阴线" :value="11"></el-option>
				<el-option label="上影十字阳线" :value="12"></el-option>
				<el-option label="上影十字阴线" :value="13"></el-option>
				<el-option label="下影十字阳线" :value="14"></el-option>
				<el-option label="下影十字阴线" :value="15"></el-option>
				<el-option label="十字阳线" :value="16"></el-option>
				<el-option label="十字阴线" :value="17"></el-option>
				<el-option label="上影阳线" :value="18"></el-option>
				<el-option label="上影阴线" :value="19"></el-option>
				<el-option label="T字阳线" :value="20"></el-option>
				<el-option label="T字阴线" :value="21"></el-option>
				<el-option label="下影阳线" :value="22"></el-option>
				<el-option label="下影阴线" :value="23"></el-option>
				<el-option label="一字线" :value="24"></el-option>
			</el-select>
			操作：
			<el-select size="mini" v-model="operation" placeholder="请选择" style="margin-right: 10px;">
				<el-option label="不操作" :value="0"></el-option>
				<el-option label="开多" :value="1"></el-option>
				<el-option label="开空" :value="-1"></el-option>
				<el-option label="多止盈" :value="2"></el-option>
				<el-option label="多止损" :value="3"></el-option>
				<el-option label="空止盈" :value="-2"></el-option>
				<el-option label="空止损" :value="-3"></el-option>
			</el-select>

			周期：
			<el-select size="mini" v-model="periodtype" placeholder="请选择" style="margin-right: 10px;">
				<el-option label="本周期" :value="1"></el-option>
				<el-option label="全周期" :value="2"></el-option>
			</el-select>

			品种：
			<el-select size="mini" v-model="varietiestype" placeholder="请选择" style="margin-right: 10px;">
				<el-option label="本品种" :value="1"></el-option>
				<el-option label="全品种" :value="2"></el-option>
			</el-select>



			<el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="marks"
				style="width: 200px; margin-right: 10px;">
			</el-input>

			<el-button v-if="!edit" size="mini" type="primary" @click="markline">确 定</el-button>
			<el-button v-if="edit" size="mini" type="warning" @click="markline">修 改</el-button>
			<el-button v-if="edit" size="mini" type="danger" @click="delmarkklinecomposeinfo">删 除</el-button>
		</div>


		<div id="main" style="width: 100%; height: 900px"></div>
		<!-- <div id="minute" style="width: 100%; height: 400px"></div> -->




		<el-dialog :title="dialog_title" :visible.sync="dialogVisible" width="40%">
			<div class="dialog-type">
				<div class="item">
					类型：
					<el-select size="mini" v-model="composetype" placeholder="请选择">
						<el-option label="未标记" :value="0"></el-option>
						<el-option label="空转多" :value="1"></el-option>
						<el-option label="多转空" :value="-1"></el-option>
						<el-option label="大阳线" :value="2"></el-option>
						<el-option label="大阴线" :value="3"></el-option>
						<el-option label="光头光脚大阳线" :value="4"></el-option>
						<el-option label="光头光脚大阴线" :value="5"></el-option>
						<el-option label="锤子阳线" :value="6"></el-option>
						<el-option label="锤子阴线" :value="7"></el-option>
						<el-option label="倒锤阳线" :value="8"></el-option>
						<el-option label="倒锤阴线" :value="9"></el-option>
						<el-option label="小阳线" :value="10"></el-option>
						<el-option label="小阴线" :value="11"></el-option>
						<el-option label="上影十字阳线" :value="12"></el-option>
						<el-option label="上影十字阴线" :value="13"></el-option>
						<el-option label="下影十字阳线" :value="14"></el-option>
						<el-option label="下影十字阴线" :value="15"></el-option>
						<el-option label="十字阳线" :value="16"></el-option>
						<el-option label="十字阴线" :value="17"></el-option>
						<el-option label="上影阳线" :value="18"></el-option>
						<el-option label="上影阴线" :value="19"></el-option>
						<el-option label="T字阳线" :value="20"></el-option>
						<el-option label="T字阴线" :value="21"></el-option>
						<el-option label="下影阳线" :value="22"></el-option>
						<el-option label="下影阴线" :value="23"></el-option>
						<el-option label="一字线" :value="24"></el-option>
					</el-select>
				</div>
				<div class="item">
					操作：
					<el-select size="mini" v-model="operation" placeholder="请选择">
						<el-option label="不操作" :value="0"></el-option>
						<el-option label="开多" :value="1"></el-option>
						<el-option label="开空" :value="-1"></el-option>
						<el-option label="多止盈" :value="2"></el-option>
						<el-option label="多止损" :value="3"></el-option>
						<el-option label="空止盈" :value="-2"></el-option>
						<el-option label="空止损" :value="-3"></el-option>
					</el-select>
				</div>
				<div class="item">
					周期：
					<el-select size="mini" v-model="periodtype" placeholder="请选择" style="margin-right: 10px;">
						<el-option label="本周期" :value="1"></el-option>
						<el-option label="全周期" :value="2"></el-option>
					</el-select>
				</div>
				<div class="item">
					品种：
					<el-select size="mini" v-model="varietiestype" placeholder="请选择" style="margin-right: 10px;">
						<el-option label="本品种" :value="1"></el-option>
						<el-option label="全品种" :value="2"></el-option>
					</el-select>
				</div>
				<div class="item">
					策略来源：
					<el-select v-model="tactics" size="mini" placeholder="请选择" style="margin-right: 10px;">
						<el-option v-for="item in hows" :key="item.value" :label="item.name"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<div class="item">
					<el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="marks">
					</el-input>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogVisible = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="markline">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				name: '',
				GroupLists: [],
				selectGroup: '',
				// 
				mark_daily_line: true, // true 标记日线
				mark_daily_choose: false, // true 单选
				data: [],
				tslineList: [],
				period: '1d',
				tactics: 1,
				hows: [{
						name: '老蒋',
						value: 1
					},
					{
						name: '刘亮',
						value: 2
					},
					{
						name: '伯飞',
						value: 3
					},
					{
						name: '东雷',
						value: 4
					}
				],
				periodList: [{
						name: '日线',
						type: '1d'
					},
					{
						name: '1分钟',
						type: '1m'
					},
					{
						name: '5分钟',
						type: '5m'
					},
					{
						name: '10分钟',
						type: '10m'
					},
					{
						name: '15分钟',
						type: '15m'
					},
					{
						name: '30分钟',
						type: '30m'
					},
					{
						name: '60分钟',
						type: '60m'
					},
					{
						name: '120分钟',
						type: '120m'
					},
					{
						name: '240分钟',
						type: '240m'
					}
				],
				options: [],
				value: '',
				loading: null,
				// 标记日线组合
				dialogVisible: false,
				dialog_title: '',
				clickItem: {},
				mark_line: [],
				kline: null,
				kline_categoryData: [],
				kline_start: '',
				kline_end: '',
				operation: 0,
				periodtype: 1,
				varietiestype: 1,
				composetype: 0,
				marks: '',
				moreClickItem: [],
				edit: 0, // 判断是不是修改
				markLineNum: {}
			};
		},
		components: {},
		created() {
			this.getKlineMarkLen()
			this.varietieslist()
			this.getKlineComposeGroupLists()
		},
		mounted() {},
		methods: {
			getKlineMarkLen() {
				let that = this
				this.$axios
					.get('/index/getKlineMarkLen', {
						params: {}
					})
					.then(function(res) {
						that.markLineNum = res.data.data
						console.log(that.markLineNum);
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			varietieslist() {
				let that = this
				this.$axios
					.get('/index/varietieslist', {
						params: {
							keywords: '',
							limit: 999,
							page: 1
						}
					})
					.then(function(res) {
						that.options = res.data.data.list
						that.value = res.data.data.list[0].code
						that.getKline();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			tagclose(item, index) {
				this.moreClickItem.splice(index, 1)
			},
			markline() {
				let that = this
				let params = {
					klineid: this.clickItem.id,
					code: this.value,
					composetype: this.composetype,
					operation: this.operation,
					marks: this.marks,
					period: this.period,
					periodtype: this.periodtype,
					varietiestype: this.varietiestype,
					tactics: this.tactics
				}

				console.log(this.clickItem)
				let startIndex = that.data.findIndex(v => {
					return v.id == (this.mark_daily_choose ? this.clickItem.id : this.moreClickItem[0].id)
				})
				console.log(startIndex)
				let endIndex = startIndex

				let ids = []
				if (!this.mark_daily_choose) {
					this.moreClickItem.forEach(v => {
						ids.push(v.id)
					})

					endIndex = that.data.findIndex(v => {
						return v.id == ids[ids.length - 1]
					})
					params.klineid = ids.join(',')
				}

				// 获取标记前n条数据id
				let preArr = that.data.slice(startIndex - this.markLineNum.pre >= 0 ? startIndex - this.markLineNum.pre :
					0, startIndex)
				let preklines = []
				preArr.forEach(v => {
					preklines.push(v.id)
				})
				// 获取标记后n条数据id
				let behind = that.data.slice(endIndex + 1, endIndex + this.markLineNum.behind >= this.data.length ? this
					.data.length : endIndex + 1 + this.markLineNum.behind)
				let behindlines = []
				behind.forEach(v => {
					behindlines.push(v.id)
				})

				console.log(preklines, behindlines);

				let allklines = []
				if (that.mark_daily_choose) {
					allklines = preklines.concat([this.clickItem.id]).concat(behindlines)
					preklines = preklines.concat([this.clickItem.id])
				} else {
					allklines = preklines.concat(ids).concat(behindlines)
					preklines = preklines.concat(ids)
				}

				params.preklineid = preklines.join(',')
				params.allklineid = allklines.join(',')

				params.klineid = params.klineid.split(',').sort(function(a, b) {
					return a - b
				}).join(',')
				params.allklineid = params.allklineid.split(',').sort(function(a, b) {
					return a - b
				}).join(',')
				params.preklineid = params.preklineid.split(',').sort(function(a, b) {
					return a - b
				}).join(',')
				// console.log(params)
				
				if (this.tactics == 4) {
					params.group_id = this.selectGroup
				} else {
					params.group_id = 0
				}
				// console.log(params);
				this.$axios({
						url: '/index/markklinecompose',
						data: params,
						method: 'post',
						// headers: { 'content-type': 'application/x-www-form-urlencoded' },
					})
					.then(function(res) {
						if (res.data.code == 1) {
							that.$notify({
								title: '成功',
								message: res.data.msg,
								type: 'success'
							});

							if (!that.mark_daily_choose) {
								that.moreClickItem = []
							}

							that.marks = ''
							that.dialogVisible = false
							that.getKline(that.value)
						} else {
							this.$notify.error({
								title: '错误',
								message: res.data.msg
							});
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			change(e) {
				console.log(e)
				this.value = e
				this.loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// let myChart = this.$echarts.init(document.getElementById('minute'));
				// myChart.clear();
				this.getKline();
			},
			changePeriod(e) {
				this.period = e
				this.loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.getKline();
			},
			getTsline(date) {
				let that = this;
				this.$axios
					.get('/index/tsline', {
						params: {
							code: this.value,
							date: date
						}
					})
					.then(function(res) {
						that.tslineList = [...res.data.data.list[0], ...res.data.data.list[1]];

						that.drawChartTSline();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			TSlineSplitData() {
				let categoryData = [];
				let price = [];
				let position = [];
				let volumes = [];
				let fsjj = [];
				let rawData = this.tslineList;

				for (let i = 0; i < rawData.length; i++) {
					categoryData.push(rawData[i].time);
					price.push(rawData[i].price);
					volumes.push(rawData[i].cjl_js);
					position.push(rawData[i].ccl);
					fsjj.push(rawData[i].fsjj);
				}

				// 计算成交量
				// for (let i = 0; i < position.length; i ++) {
				// 	let v = position[i]
				// 	console.log(`第${i}分钟：`)
				// 	if (i == 0) {
				// 		console.log('第一条跳过')
				// 	} else {
				// 		console.log(v, v - position[i - 1])
				// 		volumes[i] = Math.abs(v - position[i - 1])
				// 	}
				// }
				// console.log(volumes)

				return {
					categoryData,
					price,
					volumes,
					position,
					fsjj
				};
			},
			drawChartTSline() {
				// let that = this
				// 基于准备好的dom，初始化echarts实例  这个和上面的main对应
				let myChart = this.$echarts.init(document.getElementById('minute'));
				// 指定图表的配置项和数据
				let data = this.TSlineSplitData();

				// console.log(data.categoryData);

				let option = {
					animation: false,
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						},
						borderWidth: 1,
						borderColor: '#ccc',
						padding: 10,
						textStyle: {
							color: '#000'
						},
						position: function(pos, params, el, elRect, size) {
							const obj = {
								top: 10
							};
							obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
							return obj;
						},
						extraCssText: 'width: 200px;text-align: left;'
					},
					grid: [{
							left: '4%',
							right: '4%',
							height: '30%'
						},
						{
							left: '4%',
							right: '4%',
							height: '30%',
							top: '55%'
						}
					],
					toolbox: {
						feature: {
							dataZoom: {
								yAxisIndex: false
							},
							brush: {
								type: ['lineX', 'clear']
							}
						}
					},
					dataZoom: [{
							type: 'inside',
							xAxisIndex: [0, 1],
							start: 0,
							end: 100
						},
						{
							show: true,
							xAxisIndex: [0, 1],
							type: 'slider',
							top: '85%',
							start: 0,
							end: 100
						}
					],
					xAxis: [{
							type: 'category',
							data: data.categoryData,
							boundaryGap: false,
							axisLine: {
								onZero: false
							},
							splitLine: {
								show: false
							},
							min: 'dataMin',
							max: 'dataMax',
							axisPointer: {
								z: 100
							}
						},
						{
							type: 'category',
							gridIndex: 1,
							data: data.categoryData,
							boundaryGap: false,
							axisLine: {
								onZero: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							axisLabel: {
								show: false
							},
							min: 'dataMin',
							max: 'dataMax'
						}
					],
					yAxis: [{
							scale: true,
							splitArea: {
								show: true
							}
							// splitArea: {
							//   show: true
							// },
							// axisLabel: { show: false },
							// axisLine: { show: false },
							// axisTick: { show: false },
							// splitLine: { show: false }
						},
						{
							scale: true,
							splitArea: {
								show: true
							},
							gridIndex: 1,
							splitNumber: 4,
							// axisLabel: { show: false },
							// axisLine: { show: false },
							// axisTick: { show: false },
							// splitLine: { show: false }
							// min: function(value) { return value.min },
							// max: function(value) { return value.max }
						},
						{
							scale: true,
							splitArea: {
								show: true
							},
							gridIndex: 1,
							splitNumber: 4,
							splitLine: {
								show: false
							},
							// min: function(value) { return value.min },
							// max: function(value) { return value.max }
						}
					],
					axisPointer: {
						link: [{
							xAxisIndex: 'all'
						}],
						label: {
							backgroundColor: '#777'
						}
					},
					brush: {
						xAxisIndex: 'all',
						brushLink: 'all',
						outOfBrush: {
							colorAlpha: 0.1
						}
					},
					series: [{
							name: '价格',
							type: 'line',
							data: data.price,
							symbol: 'none',
							smooth: true,
							xAxisIndex: 0,
							yAxisIndex: 0,
							lineStyle: {
								opacity: 0.5
							}
						},
						{
							name: '分时均价',
							type: 'line',
							data: data.fsjj,
							symbol: 'none',
							smooth: true,
							xAxisIndex: 0,
							yAxisIndex: 0,
							lineStyle: {
								opacity: 0.5
							}
						},
						{
							name: '持仓量',
							type: 'line',
							data: data.position,
							symbol: 'none',
							smooth: true,
							xAxisIndex: 1,
							yAxisIndex: 2,
							lineStyle: {
								opacity: 0.5
							}
						},
						{
							name: '成交量',
							type: 'bar',
							data: data.volumes,
							symbol: 'none',
							smooth: true,
							xAxisIndex: 1,
							yAxisIndex: 1,
							lineStyle: {
								opacity: 0.5
							}
						}
					]
				};
				myChart.setOption(option);
			},

			getKline(code) {
				let that = this;
				this.loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.$axios
					.get('/index/kline', {
						params: {
							period: this.period,
							code: code || this.value,
							limit: 4000,
							page: 1,
							is_ddbl: 1
						}
					})
					.then(function(res) {
						that.data = res.data.data.list.reverse();
						that.drawChart();
						that.loading.close();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			dlchange() {
				this.getKline()
			},
			splitData() {
				let rawData = this.data;
				let categoryData = [];
				let values = [];
				let volumes = [];
				let ma5 = [];
				let ma10 = [];
				let ma20 = [];
				let position = [];
				let DIFF = [];
				let DEA = [];
				let MACD = [];
				let ZERO = [];
				let K = [];
				let D = [];
				let J = [];
				let djblmark = []

				for (let i = 0; i < rawData.length; i++) {
					// categoryData.push(`${rawData[i].date.slice(0, 4)}-${rawData[i].date.slice(4, 6)}-${rawData[i].date.slice(6, 8)}`);
					categoryData.push(rawData[i].date)
					let valuesParams = [
						rawData[i].openprice, // 开盘
						rawData[i].closeprice, // 收盘
						rawData[i].lowprice, // 最低
						rawData[i].highprice, // 最高
						rawData[i].cjl, // 成交量
					];
					let itemStyle = undefined
					if (rawData[i].composeid && this.tactics != 4) {
						itemStyle = {
							color: '#409EFF',
							color0: '#409EFF',
							borderColor: undefined,
							borderColor0: undefined
						}
					}
					if (rawData[i].composeid && !this.mark_daily_line) {
						itemStyle = {
							color: '#409EFF',
							color0: '#409EFF',
							borderColor: undefined,
							borderColor0: undefined
						}
					}
					values.push({
						value: valuesParams,
						itemStyle: itemStyle,
						id: rawData[i].id,
						composeid: rawData[i].composeid
					});

					volumes.push([i, rawData[i].cjl, valuesParams[0] > valuesParams[1] ? 1 : -1]);
					ma5.push(rawData[i].ma5);
					ma10.push(rawData[i].ma10);
					ma20.push(rawData[i].ma20);
					position.push(rawData[i].ccl);

					DIFF.push(Number(rawData[i].macd_diff).toFixed(2));
					DEA.push(Number(rawData[i].macd_dea).toFixed(2));
					MACD.push(Number(rawData[i].macd_macd).toFixed(2));
					ZERO.push(Number(rawData[i].macd_zero).toFixed(2));

					K.push(Number(rawData[i].kdj_k).toFixed(2));
					D.push(Number(rawData[i].kdj_d).toFixed(2));
					J.push(Number(rawData[i].kdj_j).toFixed(2));
						
						
					
					if (rawData[i].dingdibeiliRecordCompose.length) {
						// const upColor = '#ec0000';
						// const downColor = '#00da3c';
						
						console.log(rawData[i].dingdibeiliRecordCompose);
						
						let colors = {
							'-1': '#00da3c',
							'1': '#ec0000'
						}
						
						let names = []
						let color = ``
						rawData[i].dingdibeiliRecordCompose.forEach(v => {
							names.push(v.name)
							color = colors[v.operation]
						})

						djblmark.push(
							{
								coord: [i, Number(rawData[i].macd_diff).toFixed(2)],
								symbol: 'pin',
								symbolSize: 70,
								value: names.join('-'),
								animation: true,
								label: {
									show: true,
									color: '#FFF',
									fontSize: '10'
								},
								itemStyle: { color: color }
							},
						)
					}


				}

				return {
					categoryData: categoryData,
					values: values,
					volumes: volumes,
					ma5: ma5,
					ma10: ma10,
					ma20: ma20,
					position: position,
					DIFF,
					DEA,
					MACD,
					ZERO,
					K,
					D,
					J,
					djblmark
				};
			},
			drawChart() {
				let that = this;
				// 基于准备好的dom，初始化echarts实例  这个和上面的main对应
				let myChart = this.$echarts.init(document.getElementById('main'));
				myChart.off("click");
				// 指定图表的配置项和数据
				let data = this.splitData();
				this.kline_categoryData = data.categoryData
				const upColor = '#ec0000';
				const downColor = '#00da3c';
				// console.log(data)

				console.log(data.values);

				let option = {
					animation: false,
					// legend: {
					//   bottom: 10,
					//   left: 'center',
					//   // data: ['Dow-Jones index', 'MA5', 'MA10', 'MA20', 'MACD_DIFF', 'MACD_DEA', 'MACD_MACD', 'MACD_ZERO']
					//   data: ['Dow-Jones index', 'MA5', 'MA10', 'MA20']
					// },
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross'
						},
						borderWidth: 1,
						borderColor: '#ccc',
						padding: 10,
						textStyle: {
							color: '#000'
						},
						position: function(pos, params, el, elRect, size) {
							const obj = {
								top: 10
							};
							obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
							return obj;
						},
						extraCssText: 'width: 200px;text-align: left;'
					},
					axisPointer: {
						link: [{
							xAxisIndex: 'all'
						}],
						label: {
							backgroundColor: '#777'
						}
					},
					toolbox: {
						feature: {
							dataZoom: {
								yAxisIndex: false
							},
							brush: {
								type: ['lineX', 'clear']
							}
						}
					},
					brush: {
						xAxisIndex: 'all',
						brushLink: 'all',
						outOfBrush: {
							colorAlpha: 0.1
						}
					},
					visualMap: {
						show: false,
						seriesIndex: 4,
						dimension: 2,
						pieces: [{
								value: 1,
								color: downColor
							},
							{
								value: -1,
								color: upColor
							}
						]
					},
					grid: [
						// {
						// 	left: '10%',
						// 	right: '8%',
						// 	height: '50%'
						// },
						// {
						// 	left: '10%',
						// 	right: '8%',
						// 	top: '63%',
						// 	height: '16%'
						// }
						{
							left: '10%',
							right: '8%',
							height: '30%'
						},
						{
							left: '10%',
							right: '8%',
							top: '43%',
							height: '14%'
						},
						{
							left: '10%',
							right: '8%',
							top: '60%',
							height: '14%'
						},
						{
							left: '10%',
							right: '8%',
							top: '78%',
							height: '14%'
						}
					],
					xAxis: [{
							type: 'category',
							data: data.categoryData,
							boundaryGap: false,
							axisLine: {
								onZero: false
							},
							splitLine: {
								show: false
							},
							min: 'dataMin',
							max: 'dataMax',
							axisPointer: {
								z: 100
							}
						},
						{
							type: 'category',
							gridIndex: 1,
							data: data.categoryData,
							boundaryGap: false,
							axisLine: {
								onZero: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							axisLabel: {
								show: false
							},
							min: 'dataMin',
							max: 'dataMax'
						},
						{
							type: 'category',
							gridIndex: 2,
							data: data.categoryData,
							boundaryGap: false,
							axisLine: {
								onZero: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							axisLabel: {
								show: false
							},
							min: 'dataMin',
							max: 'dataMax'
						},
						{
							type: 'category',
							gridIndex: 3,
							data: data.categoryData,
							boundaryGap: false,
							axisLine: {
								onZero: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							axisLabel: {
								show: false
							},
							min: 'dataMin',
							max: 'dataMax'
						}
					],
					yAxis: [{
							scale: true,
							splitArea: {
								show: true
							}
						},
						{
							scale: true,
							splitArea: {
								show: true
							},
							gridIndex: 1,
							splitNumber: 2,
							axisLabel: {
								show: true
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							// splitLine: { show: false }
						},
						{
							scale: true,
							splitArea: {
								show: true
							},
							gridIndex: 2,
							splitNumber: 2,
							axisLabel: {
								show: true
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							// splitLine: { show: false }
						},
						{
							scale: true,
							splitArea: {
								show: true
							},
							gridIndex: 3,
							splitNumber: 2,
							axisLabel: {
								show: true
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							// splitLine: { show: false }
						}
					],
					dataZoom: [{
							type: 'inside',
							xAxisIndex: [0, 1, 2, 3],
							// start: 50,
							// end: 100,
							startValue: data.values.length - 51,
							endValue: data.values.length - 1,
						},
						// {
						// 	show: true,
						// 	xAxisIndex: [0, 1, 2, 3],
						// 	type: 'slider',
						// 	top: '85%',
						// 	start: 90,
						// 	end: 100
						// },
						// {
						// 	show: true,
						// 	xAxisIndex: [0, 1, 2, 3],
						// 	type: 'slider',
						// 	top: '85%',
						// 	start: 90,
						// 	end: 100
						// },
						{
							show: true,
							xAxisIndex: [0, 1, 2, 3],
							type: 'slider',
							top: '93%',
							startValue: data.values.length - 51,
							endValue: data.values.length - 1
						}
					],
					series: [{
							name: 'Dow-Jones index',
							type: 'candlestick',
							barWidth: 7,
							data: data.values,
							itemStyle: {
								color: upColor,
								color0: downColor,
								borderColor: undefined,
								borderColor0: undefined,
							}
						},
						// {
						//   name: 'MACD_DIFF',
						//   type: 'line',
						//   xAxisIndex: 1,
						//   yAxisIndex: 1,
						//   data: data.DIFF
						// },
						// {
						//   name: 'MACD_DEA',
						//   type: 'line',
						//   xAxisIndex: 1,
						//   yAxisIndex: 1,
						//   data: data.DEA
						// },
						// {
						//   name: 'MACD_MACD',
						//   type: 'line',
						//   xAxisIndex: 1,
						//   yAxisIndex: 1,
						//   data: data.MACD
						// },
						// {
						//   name: 'MACD_ZERO',
						//   type: 'line',
						//   xAxisIndex: 1,
						//   yAxisIndex: 1,
						//   data: data.ZERO
						// },
						{
							name: 'MA5',
							type: 'line',
							symbol: 'none',
							data: data.ma5,
							smooth: true,
							itemStyle: {
								normal: {
									color: '#43495c',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: 'MA10',
							type: 'line',
							data: data.ma10,
							symbol: 'none',
							smooth: true,
							itemStyle: {
								normal: {
									color: '#F4A460',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: 'MA20',
							type: 'line',
							data: data.ma20,
							symbol: 'none',
							smooth: true,
							itemStyle: {
								normal: {
									color: '#b72d5c',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: '成交量',
							type: 'bar',
							xAxisIndex: 1,
							yAxisIndex: 1,
							data: data.volumes
						},
						{
							name: '持仓量',
							symbol: 'none',
							type: 'line',
							xAxisIndex: 1,
							yAxisIndex: 1,
							data: data.position,
							itemStyle: {
								normal: {
									color: '#b72d5c',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: 'KDJ_K',
							symbol: 'none',
							type: 'line',
							xAxisIndex: 2,
							yAxisIndex: 2,
							data: data.K,
							itemStyle: {
								normal: {
									color: '#43495c',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: 'KDJ_D',
							symbol: 'none',
							type: 'line',
							xAxisIndex: 2,
							yAxisIndex: 2,
							data: data.D,
							itemStyle: {
								normal: {
									color: '#d27c37',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: 'KDJ_J',
							symbol: 'none',
							type: 'line',
							xAxisIndex: 2,
							yAxisIndex: 2,
							data: data.J,
							itemStyle: {
								normal: {
									color: '#b72d5c',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: 'MACD_MACD',
							symbol: 'none',
							type: 'bar',
							xAxisIndex: 3,
							yAxisIndex: 3,
							data: data.MACD,
							barWidth: 2,
							itemStyle: {
								normal: {
									color: function(params) {
										return params.data > 0 ? upColor : downColor
									},
								}
							},
							markPoint: {
								data: data.djblmark,
								label: {
									normal: {
										formatter: function(param) {
											let pArr = param.value.split('-')
											console.log(param.value, pArr);
											return pArr.join(`\n`)
										}
									}
								}
							}
						},
						{
							name: 'MACD_DEA',
							symbol: 'none',
							type: 'line',
							xAxisIndex: 3,
							yAxisIndex: 3,
							data: data.DEA,
							itemStyle: {
								normal: {
									color: '#F4A460',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						{
							name: 'MACD_DIFF',
							symbol: 'none',
							type: 'line',
							xAxisIndex: 3,
							yAxisIndex: 3,
							data: data.DIFF,
							itemStyle: {
								normal: {
									color: '#42435b',
									lineStyle: {
										width: 1 //设置线条粗细
									}
								}
							}
						},
						// {
						// 	name: 'MACD_ZERO',
						// 	symbol: 'none',
						// 	type: 'line',
						// 	xAxisIndex: 3,
						// 	yAxisIndex: 3,
						// 	data: data.ZERO
						// }
					]
				};

				// 使用刚指定的配置项和数据显示图表。
				myChart.on('click', async function(params) {
					console.log(params)
					if (params.data.composeid) {
						that.moreClickItem = []
						that.edit = params.data.composeid
						let res = await that.getmarkklinecomposeinfo(params.data.composeid)
						let d = res.data
						let idsStr = d.klineid.split(',')
						idsStr.forEach(v => {
							let clickItem = that.data.find(i => {
								// return v.date == params.name.replace(/-/g, '')
								return v == i.id
							})
							that.moreClickItem.push(clickItem)
						})
						that.composetype = d.composetype
						that.operation = d.operation
						that.marks = d.marks
						return
					}

					if (that.mark_daily_line) {
						that.composetype = 0
						that.operation = 0
						that.marks = ''
						if (that.edit) {
							that.moreClickItem = []
						}
						that.edit = 0
						// 单选标记，直接点击k先
						if (that.mark_daily_choose) {
							let clickItem = that.data.find(v => {
								// return v.date == params.name.replace(/-/g, '')
								return v.date == params.name
							})
							that.dialogVisible = true
							that.dialog_title = params.name
							that.clickItem = clickItem
						} else {
							let clickItem = that.data.find(v => {
								// return v.date == params.name.replace(/-/g, '')
								return v.date == params.name
							})
							// 判断存不存在
							let item = that.moreClickItem.find(v => {
								return v.id == clickItem.id
							})
							let index = that.moreClickItem.findIndex(v => {
								return v.id == clickItem.id
							})
							if (item) {
								data.values[params.dataIndex].itemStyle = undefined
								that.moreClickItem.splice(index, 1)
							} else {
								that.moreClickItem.push(clickItem)
								data.values[params.dataIndex].itemStyle = {
									color: '#409EFF',
									color0: '#409EFF',
									borderColor: undefined,
									borderColor0: undefined
								}
							}
							option.dataZoom[0].start = myChart.getOption().dataZoom[0].start
							option.dataZoom[0].end = myChart.getOption().dataZoom[0].end
							myChart.setOption(option);
						}
					} else {
						that.$router.push({
							path: `/about?name=${params.name}&value=${that.value}`
						})
					}


				});
				myChart.setOption(option);
			},
			getmarkklinecomposeinfo(id) {
				return new Promise((resolve, reject) => {
					this.$axios
						.get('/index/getmarkklinecomposeinfo', {
							params: {
								id: id
							}
						})
						.then(function(res) {
							resolve(res.data)
						})
						.catch(function(error) {
							console.log(error);
							reject(error)
						});
				})
			},
			delmarkklinecomposeinfo() {
				let that = this
				this.$confirm('此操作将永久删除该标记, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios
						.get('/index/delmarkklinecomposeinfo', {
							params: {
								id: that.edit
							}
						})
						.then(function(res) {
							that.$notify.success({
								title: '成功',
								message: res.data.msg
							});
							that.moreClickItem = []
							that.getKline(that.value)
						})
						.catch(function() {
							that.$notify.error({
								title: '错误',
								message: `删除失败！`
							});
						});
				}).catch(() => {
					that.$notify.error({
						title: '错误',
						message: `删除失败！`
					});
				});

			},
			getKlineComposeGroupLists() {
				let that = this
				this.$axios
					.get('/index/getKlineComposeGroupLists', {
						params: {}
					})
					.then(function(res) {
						that.GroupLists = res.data.data.list
						that.selectGroup = res.data.data.list[0].id
					})
					.catch(function() {
						that.$notify.error({
							title: '错误',
							message: `删除失败！`
						});
					});
			},
			submitKlineComposeGroup() {
				let that = this
				this.$axios({
						url: '/index/submitKlineComposeGroup',
						data: {
							name: this.name,
							tactics: this.tactics
						},
						method: 'post',
						// headers: { 'content-type': 'application/x-www-form-urlencoded' },
					}).then(function(res) {
						that.$notify.success({
							title: '添加成功',
							message: res.data.msg
						});
						that.getKlineComposeGroupLists()
					})
					.catch(function() {
						that.$notify.error({
							title: '错误',
							message: `删除失败！`
						});
					});
			},
			time() {
				this.$router.push({
					path: `/time-share`
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.dialog-type {
		.item {
			margin-top: 10px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.choose-box {
		display: flex;
		align-items: center;

		.mark_daily_line {
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	.select-how {
		margin-left: 20px;
	}
</style>